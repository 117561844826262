// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom styles for Slick slider pagination dots */
.slick-dots li button:before {
    font-size: 17px !important;
    width: 17px !important;
    height: 17px !important;
    color: #D9D9D9 !important;
    opacity: 1 !important; /* Set the color you want for the dots */
    
  }
  
  .slick-dots li.slick-active button:before {
    color: #2E94CB !important; /* Set the color you want for the active dot */
  }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,mDAAmD;AACnD;IACI,0BAA0B;IAC1B,sBAAsB;IACtB,uBAAuB;IACvB,yBAAyB;IACzB,qBAAqB,EAAE,wCAAwC;;EAEjE;;EAEA;IACE,yBAAyB,EAAE,8CAA8C;EAC3E","sourcesContent":["/* Custom styles for Slick slider pagination dots */\r\n.slick-dots li button:before {\r\n    font-size: 17px !important;\r\n    width: 17px !important;\r\n    height: 17px !important;\r\n    color: #D9D9D9 !important;\r\n    opacity: 1 !important; /* Set the color you want for the dots */\r\n    \r\n  }\r\n  \r\n  .slick-dots li.slick-active button:before {\r\n    color: #2E94CB !important; /* Set the color you want for the active dot */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
